import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';


class Contribute extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      amount: '', 
      showWaiting: false,
      showWarning: false,
      showSuccess: false,
      userId:0,
      ipAddress:''
    };
    
  }

  
  
  handleInputChange = async (e) => {
    this.setState({amount: e.target.value});
  }
  handleOnSubmit = async () => {
    fetch(`
    https://api.ipify.org?format=json`)
            .then(response => response.json())
            .then(data => {
              console.log(data)
              this.setState({ipAddress: data.ip});
            });

    this.setState({showWaiting: true});
    console.log(this.state.amount)
    console.log(this.props)
    let user = localStorage.getItem('user');
    if(user){

    }else{
      console.log('login');
      //this.props.history.push('/login');
      window.open("/login");
      console.log(user);
      return
    }
    let num = Number(this.state.amount);
    user = JSON.parse(user);
    /**************** */
    setTimeout(() => {
      console.log(this.state)
      fetch(`
    https://crearecoin.com/api/coin/bye_coin.php?
    amount=${this.state.amount}&address=${this.props.address[0]}&ip=${this.state.ipAddress}
    &symbol=${this.props.symbol}&user_id=${user.id}`)
            .then(response => response.json())
            .then(data => {
            });
    }, 500);
    
  
    /**************** */
    console.log(num)
    console.log( num * this.props.tokenprice)
    console.log( this.props.address[0])
    let res = this.props.greetHandler.methods.buyTokens(num).send({
      from: this.props.address[0],
      value: num * this.props.tokenprice
    });
    if(res){
      this.setState({showWaiting: false});
      this.setState({showSuccess: true});
      console.log(res)
    }
    
  }; 
 

  

  render() {
    return (
      <div>
        <div class="contribute">Contribute</div>
          <div class=" contributeContainer">
            <form>
              <div class="buyCoins">
                <div class="amountToBuy">Numbers of coins buy:</div>
                <TextField 
                onChange={this.handleInputChange}

                > </TextField>
                <div> ≈  { this.props.symbol } </div>
                {/* <div class="etherDC"> (1 ETH ≈ 100 + 25 (Bonus) DC) </div> */}
              </div>
              <div class="buttonBuy">
                <Button 
                type="button" variant="contained" 
                color="primary" value="Submit"
                onClick={ this.handleOnSubmit }
                >Buy { this.props.symbol } Tokens  </Button>
              </div>
            </form>

            {
              this.state.showWarning && 
              <div class="flex errorMessage">
              <i class="material-icons">error_outline</i>
              <div >Error Message: asydassadasdas</div>
              </div>
            }
            
            { 
            this.state.showWaiting && 
            <div class="flex loadingContainer">
              <div>
                <div class="loadingText"> Waiting For Confirmation</div>
                <div class="loadTextSub">(this can take up to 30 seconds)</div>
              </div>
              <CircularProgress/>
            </div>
            }
            {
              this.state.showSuccess &&
              <div class="flex successfully">You successfully bought { this.props.symbol } Coins!</div>
            }

            

        </div>
      </div>
    );
  }
}

export default Contribute;
