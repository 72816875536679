import React, { Component } from 'react';
import Button from "react-bootstrap/Button";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './app.min.css';
import Test from "./Test";
// https://themesbrand.com/dason/layouts/default/tables-basic.html

class Rollmaster extends Component{
    constructor(props){
        super(props)
        this.state = {
            coins: [],
            userName: '',
            userId: 0,
            userType:0,
            role:'',
            roleDes:'',
            roleList: [],
            roleid:0,
            noCoin: '',
            offerDetails: '',
            holdingPred: '',
            rows: [{}],
            perc:[],
            days:[],
            user:'',
        };
        this.logOut = this.logOut.bind(this);
        this.roleHendl = this.roleHendl.bind(this);
        this.roleDesHendel = this.roleDesHendel.bind(this);
        this.submitRole = this.submitRole.bind(this);

        this.roleNocoinHendel = this.roleNocoinHendel.bind(this);
        this.roleOfferDetailsHendel = this.roleOfferDetailsHendel.bind(this);
        this.roleHoldingPreHendel = this.roleHoldingPreHendel.bind(this);

        this.handler = this.handler.bind(this)
    }
    /************************************* */

    handler() {
      this.setState({
        someVar: 'some value'
      })
    }
  
    handleChange = idx => e => {
        const { name, value } = e.target;
        let rows = [this.state.rows];
        rows[idx] = {
          [name]: value
        };
        this.setState({
          rows
        });
        //this.setState({perc : [...this.state.perc, e.target.value] })
        
      };
      handleChange_2 = idx => e => {
        const { name, value } = e.target;
        const rows = [...this.state.rows];
        rows[idx] = {
          [name]: value
        };
        this.setState({ days: [...this.state.days, e.target.value]})

        this.setState({
          rows
        });
      };
      handleAddRow = () => {
        const item = {
          name: "",
          mobile: ""
        };
        console.log([...this.state.rows, item]);
        this.setState({
          rows: [...this.state.rows, item]
        });
      };
      handleRemoveRow = () => {
        this.setState({
          rows: this.state.rows.slice(0, -1)
        });
      };
      handleRemoveSpecificRow = (idx) => () => {
        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
      }
    /************************************* */

    roleHoldingPreHendel(e){
        this.setState({holdingPred: e.target.value});
    }
    roleOfferDetailsHendel(e){
        this.setState({offerDetails: e.target.value});
    }
    roleNocoinHendel(e){
        this.setState({noCoin: e.target.value});
    }
    roleHendl(e){
        this.setState({role: e.target.value});
    }
    roleDesHendel(e){
        this.setState({roleDes: e.target.value});
    }
    submitRole(){
        console.log(this.state); //return;

        if(this.state.roleid ==0 ){
            fetch(`
            https://crearecoin.com/api/coin/role.php?
            name=${this.state.role}&des=${this.state.roleDes}&
            noCoin=${this.state.noCoin}&offerDetails=${this.state.offerDetails}&
            days=${this.state.days}&perc=${this.state.perc}&
            holdingPred=${this.state.holdingPred}&rows=${JSON.stringify(this.state.rows)}
            `)
                    .then(response => response.json())
                    .then(data => {
                        console.log(data)
                        this.setState({role: ''});
                        this.setState({roleDes: ''});
                        this.setState({noCoin: ''});
                        this.setState({offerDetails: ''});
                        this.setState({holdingPred: ''});
                        this.getAllRolle();
                    });

        }else{
            fetch(`
            https://crearecoin.com/api/coin/role.php?
            name=${this.state.role}&des=${this.state.roleDes}&id=${this.state.roleid}&
            noCoin=${this.state.noCoin}&offerDetails=${this.state.offerDetails}&
            holdingPred=${this.state.holdingPred}&rows=${JSON.stringify(this.state.rows)}`)
                    .then(response => response.json())
                    .then(data => {
                        console.log(data)
                        this.setState({role: ''});
                        this.setState({roleDes: ''});
                        this.setState({roleid: 0});
                        this.setState({noCoin: ''});
                        this.setState({offerDetails: ''});
                        this.setState({holdingPred: ''});
                        this.getAllRolle();
                    });
        }
       
    }

    getAllCoins(){
        setTimeout(() => {
            fetch(`
    https://crearecoin.com/api/coin/get_all_coins.php?id=`+this.state.userId+`&type=`+this.state.userType)
            .then(response => response.json())
            .then(data => {
                if(!data.error){
                    this.setState({coins: data.result});
                }
                //
                console.log(this.state.coins);
            });
        }, 200);
    }
    getAllRolle(){
        fetch(`
        https://crearecoin.com/api/coin/role-list.php`)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({roleList: data.result});
            });
    }

    componentDidMount() {
        console.log('hit ')
        let user =JSON.parse(localStorage.getItem('user'));
        if(!user || user=== null){
            window.location.replace('./');
        }
        this.getAllCoins();
        if(user){
            this.setState({userName: user.name});
            this.setState({userId: user.id});
            this.setState({userType: user.user_type});
            this.setState({user: user});
        }
        this.getAllRolle();
        
    }


    logOut(){
        console.log('logout')
        localStorage.removeItem("user");
        this.props.history.push({ 
            pathname: '/login'
        });
    }
    handleEdit(e){
        //console.log(e)
        this.setState({role: e.name});
        this.setState({roleDes: e.des});
        this.setState({roleid: e.id});

        this.setState({noCoin: e.noCoin});
        this.setState({offerDetails: e.offerDetails});
        this.setState({holdingPred: e.holdingPred});
        
        var rows = JSON.parse(e.rows);
        //console.log(rows)
        this.setState({rows: rows});
        //console.log(this.state);
    }
    handleDelete(e){
        fetch(`
    https://crearecoin.com/api/coin/role.php?delete=`+e.id)
            .then(response => response.json())
            .then(data => {
                this.getAllRolle();
            });
    }

    handleCallback = (childData) =>{
      this.setState({rows: childData});
  }


    render(){
        return(
            <>
            <header class="menu-sticky">
                <div class="topnav" id="myTopnav">
                
                <a href="./"><img src='images/Creare-Coin_logo.png' /> </a>
                
                { this.state.userId != -1 && 
                <a class="log" onClick={this.logOut} style={{float:'right' } }>
                        Logout
                </a> }
               
                </div>
            </header>
            <div class="main-contain">
                <section class="iq-tranding-Platform trending-2">
                    <div class="container-floud">
                    <div class="row black-background">
                            <div class="col-sm-6">
                                <div class="heading-title">
                                    
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="heading-title header-test">
                                    <p>{this.state.user.address } &nbsp;&nbsp;
                                    || &nbsp;&nbsp;
                                    {this.state.user.balance } {this.state.user.symbol }
                                    </p>
                                </div>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div className="col-sm-2 overview-block-pb">
                                <ul className="menu">
                                    <li><a href="/dashboard">DASHBOARD</a> </li>
                                    <li><a href="/rollmaster">User role master</a> </li>
                                    <li><a href="/adduser">Subscription Master</a> </li>
                                    <li><a href="/invite">Invite</a> </li>

                                   <li><a href="/report">Reports & Graphs</a> </li>
                                </ul>
                            </div>
                            <div className="col-sm-10 white-background">
                              <div className='row'>
                              <div class="col-xl-6 col-md-7">
                                <div class="ms-panel">
                                  <div class="ms-panel-header">
                                      <h6>Add Role</h6>
                                  </div>
                                  <div class="ms-panel-body">
                                  <div class="form-row">
                                        <div class="col-md-12 mb-3">
                                            <label for="validationCustom0001">Role name</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" 
                                                id="validationCustom0001" placeholder=" Name"
                                                onChange={this.roleHendl}
                                                value={this.state.role}
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-12 mb-3">
                                            <label for="validationCustom0002">Role description</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" id="validationCustom0002" 
                                                placeholder="Enter Description"
                                                onChange={this.roleDesHendel}
                                                value={this.state.roleDes} />

                                            </div>
                                        </div>
                                        <div class="col-md-12 mb-3">
                                            <label for="validationCustom0002">Total no of coins:</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" id="validationCustom0002" 
                                                placeholder="No. coin"
                                                onChange={this.roleNocoinHendel}
                                                value={this.state.noCoin} />

                                            </div>
                                        </div>
                                        <div class="row">
                                        <div class="col-md-12 mb-3">
                                            <label for="validationCustom0002">Offer details</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" id="validationCustom0002" 
                                                placeholder="Offer details"
                                                onChange={this.roleOfferDetailsHendel}
                                                value={this.state.offerDetails} />

                                            </div>
                                        </div>

                                        
                                        </div>

                                        <div className="col-md-12 column">
                                        <Test 
                                        totalCoin={this.state.noCoin}
                                        parentCallback = {this.handleCallback}
                                        editData = {this.state.rows}
                                          />
                                        </div>
                                        {/* <div className="col-md-12 column">
                                  <table
                                    className="table table-bordered table-hover"
                                    id="tab_logic"
                                  >
                                    <thead>
                                      <tr>
                                        <th className="text-center">Percentage</th>
                                        <th className="text-center">Holding period (days)</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.rows.map((item, idx) => (
                                        <tr id="addr0" key={idx}>
                                          <td>
                                            <input
                                              type="text"
                                              name="name"
                                              value={this.state.rows[idx].name}
                                              onChange={this.handleChange(idx)}
                                              className="form-control"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="date"
                                              name="mobile"
                                              value={this.state.rows[idx].mobile}
                                              onChange={this.handleChange(idx)}
                                              className="form-control"
                                            />
                                          </td>
                                          <td>
                                            <button
                                              className="btn btn-outline-danger btn-sm"
                                              onClick={this.handleRemoveSpecificRow(idx)}
                                            >
                                              Remove
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                  <button onClick={this.handleAddRow} className="btn btn-primary">
                                    Add Row
                                  </button>
                                </div> */}

                                        { this.state.roleid ==0 &&
                                            <button class="btn btn-primary mt-4 d-inline w-20"
                                            onClick={this.submitRole}
                                            type="button">Submit</button>
                                        }
                                        { this.state.roleid > 0 &&
                                            <button class="btn btn-warning mt-4 d-inline w-20"
                                            onClick={this.submitRole}
                                            type="button">Update</button>
                                        }
                                        
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-5">
                                                  <div class="card">
                                                      <div class="card-header">
                                                          <h4 class="card-title">List</h4>
                                                      </div>
                                                      <div class="card-body">
                                                          <div class="table-responsive hight-fixed">
                                                          

                                                              <table class="table mb-0">

                                                                  <thead>
                                                                      <tr>
                                                                          <th>#</th>
                                                                          <th>Name</th>
                                                                          <th>Description</th>
                                                                          <th>Action</th>
                                                                      </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                  { this.state.roleList &&
                                                                      this.state.roleList.map((role, index) => (
                                                                      <tr key={index}>
                                                                          <th scope="row">1</th>
                                                                          <td>{role.name} </td>
                                                                          <td>{role.des} </td>
                                                                          <td>
                                                                          <button type="button" 
                                                                          class="btn btn-info btn-rounded waves-effect waves-light"
                                                                          onClick={() => this.handleEdit(role)}
                                                                          >Edit</button>
                                                                          <button type="button" 
                                                                          class="btn btn-danger btn-rounded waves-effect waves-light"
                                                                          onClick={() => this.handleDelete(role)}
                                                                          >Delete</button>
                                                                          </td>
                                                                      </tr>
                                                                      ))
                                                                      }
                                                                  </tbody>
                                                              </table>
                                                            
                                                          </div>
                                                      </div>
                                                  </div>
                              </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            </>
        )
    }
}
export default Rollmaster;