import React, {Component} from 'react';

class Roadmap extends Component {

  render(){
    return (
      <div>
        <div class="roadmap">  </div>
        <div class="roadmapImage">
          <img src=""/>
        </div>
      </div>
    );
  }
}

export default Roadmap;
