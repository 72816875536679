import React, { Component } from 'react';
import Button from "react-bootstrap/Button";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './app.min.css';
// https://themesbrand.com/dason/layouts/default/tables-basic.html

class Mypanel extends Component{
    constructor(props){
        super(props)
        this.state = {
            coins: [],
            userName: '',
            userId: 0,
            userType:0,
            userData:'',
            roleDetails:'',
        };
        this.logOut = this.logOut.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
    }
    handleRoleChange(role) {
        fetch(`
    https://crearecoin.com/api/coin/get_role.php?name=${role}`)
            .then(response => response.json())
            .then(data => {
                var res = data.result;
                this.setState({roleDetails: res[0]});
            });
    }
    getAllCoins(){
        setTimeout(() => {
            fetch(`
    https://crearecoin.com/api/coin/get_all_coins.php?id=`+this.state.userId+`&type=`+this.state.userType)
            .then(response => response.json())
            .then(data => {
                if(!data.error){
                    this.setState({coins: data.result});
                }
                //
                console.log(this.state.coins);
            });
        }, 200);
    }
    componentDidMount() {
        console.log('hit ')
        let user =JSON.parse(localStorage.getItem('user'));
        if(!user || user=== null){
            window.location.replace('./');
        }
        this.getAllCoins();
        if(user){
            this.setState({userName: user.name});
            this.setState({userId: user.id});
            this.setState({userType: user.user_type});
            this.setState({userData: user});
            if(user.user_type==2 || user.user_type=='2'){
                this.handleRoleChange(user.role);
            }
        }
        
    }
    logOut(){
        console.log('logout')
        localStorage.removeItem("user");
        this.props.history.push({ 
            pathname: '/login'
        });
    }

    render(){
        return(
            <div>
                <div class="ms-aside-overlay ms-overlay-right ms-toggler" 
                data-target="#ms-recent-activity" data-toggle="slideRight"></div>

                <div id="ms-side-nav" class="side-nav fixed ">
                <div class="logo-sn ms-d-block-lg">
                    <a class="pl-0 ml-0 text-center" href="./"> 
                    <img class="imggolgo" src="./shkl.jpeg" />
                     </a>

                    {this.state.userType ==2 && 
                    <h5 class="text-center text-white mt-2">{this.state.userData.role } </h5>
                    }
                    <h5 class="text-center text-white mt-2">{this.state.userName} </h5>
                  
                </div>

                <ul class="accordion ms-main-aside fs-14" id="side-nav-accordion">
                    <li class="menu-item">
                        <a href="/mypanel" class="has-chevron" data-toggle="collapse" data-target="#dashboard" aria-expanded="false" aria-controls="dashboard">
                        <span><i class="material-icons fs-16">dashboard</i>Dashboard </span>
                        </a>
                        <ul id="dashboard" class="collapse" aria-labelledby="dashboard" data-parent="#side-nav-accordion">
                            <li> <a href="index.html">Medjestic</a> </li>
                        </ul>
                    </li>
                    {this.state.userType==1 &&
                    <li class="menu-item">
                        <a href="/rollmaster" class="has-chevron" data-toggle="collapse" data-target="#dashboard" aria-expanded="false" aria-controls="dashboard">
                        <span><i class="material-icons fs-16">dashboard</i>User role master</span>
                        </a>
                        <ul id="dashboard" class="collapse" aria-labelledby="dashboard" data-parent="#side-nav-accordion">
                            <li> <a href="index.html">Medjestic</a> </li>
                        </ul>
                    </li>
                     }
                     {this.state.userType==1 &&
                    <li class="menu-item">
                        <a href="/adduser" class="has-chevron" data-toggle="collapse" data-target="#dashboard" aria-expanded="false" aria-controls="dashboard">
                        <span><i class="material-icons fs-16">dashboard</i>Subscription Master</span>
                        </a>
                    </li>
                     }
                     {this.state.userType==1 | this.state.userType==2 &&
                    <li class="menu-item">
                        <a href="/invite" class="has-chevron" data-toggle="collapse" data-target="#dashboard" aria-expanded="false" aria-controls="dashboard">
                        <span><i class="material-icons fs-16">dashboard</i>Invite</span>
                        </a>
                    </li>
                     }  
                    {this.state.userType==3 &&
                    <li class="menu-item">
                        <a href="/profile" class="has-chevron" data-toggle="collapse" data-target="#dashboard" aria-expanded="false" aria-controls="dashboard">
                        <span><i class="material-icons fs-16">dashboard</i>Profile</span>
                        </a>
                    </li>
                     } 
                    
                </ul>
                </div>

                <main class="body-content">
   <nav class="navbar ms-navbar">
     <div class="ms-aside-toggler ms-toggler pl-0" data-target="#ms-side-nav" data-toggle="slideLeft">
       <span class="ms-toggler-bar bg-white"></span>
       <span class="ms-toggler-bar bg-white"></span>
       <span class="ms-toggler-bar bg-white"></span>
     </div>
     <div class="logo-sn logo-sm ms-d-block-sm">
       <a class="pl-0 ml-0 text-center navbar-brand mr-0" href="index.html">
           <img src="assets/img/medjestic-logo-84x41.png" alt="logo" /> </a>
     </div>
     <ul class="ms-nav-list ms-inline mb-0" id="ms-nav-options">
       <li class="ms-nav-item ms-nav-user dropdown">
         <a id="userDropdown" onClick={this.logOut} > 
         <i class="material-icons fs-16">exit_to_app</i>
          </a>
       </li>
     </ul>
     
   </nav>
   <div class="ms-content-wrapper">
       {this.state.userType==2 &&
        <div class="row">
        <div class="col-xl-12 col-md-12">
           <div class="ms-panel">
              <div class="ms-panel-header">
                 <h6>Coins subscription info</h6>
              </div>
              <div class="ms-panel-body">
                 <div class="table-responsive">
                 <table className="table table-bordered table-hover"id="tab_logic">
                    <thead>
                        <tr>
                            <th className="text-center">Percentage</th>
                            <th className="text-center">Coin</th>
                            <th className="text-center">Holding period (days)</th>
                            <th>Action </th>
                        </tr>
                    </thead>
                    <tbody>
                                            {this.state.roleDetails && this.state.roleDetails.rows &&
                                            <>
                                            {this.state.roleDetails.rows.map((row, idx)=>( 
                                                <tr  key={idx}>
                                                    <td>{ row.perc }</td>
                                                    <td>{row.coin } </td>
                                                    <td>{row.days }</td>
                                                    <td>{
                                                        new Date(row.days).getTime() >= new Date().getTime() &&
                                                        
                                                        <>
                                                        Completed</>
                                                        }

                                                        {
                                                        new Date(row.days).getTime() <= new Date().getTime() &&
                                                        <>
                                                        Pending</>
                                                        }
                                                    </td>
                                                </tr>
                                            )) }
                                            </>
                                            }
                                            </tbody>
                </table>
                 </div>
              </div>
           </div>
        </div>
     </div>
        }
    {this.state.userType !=2 &&
      <div class="row">
         <div class="col-xl-12 col-md-12">
            <div class="ms-panel">
               <div class="ms-panel-header">
                  <h6>Coins list</h6>
               </div>
               <div class="ms-panel-body">
                  <div class="table-responsive">
                     <table class="table table-bordered">
                        <thead>
                           <tr>
                              <th scope="col">#</th>
                              <th scope="col">Date</th>
                              <th scope="col">Address</th>
                              <th scope="col">Coin</th>
                              <th scope="col">Symbol</th>
                              <th scope="col">User name</th>
                              <th scope="col">IP</th>
                           </tr>
                        </thead>
                        <tbody>
                            { this.state.coins &&
                        this.state.coins.map((coin, index) => (
                            <tr key={index}>
                              <th scope="row">{index+1} </th>
                              <td>{coin.create_date} </td>
                              <td>{coin.address} </td>
                              <td>{coin.num_coin}</td>
                              <td>{coin.symbol}</td>
                              <td>{coin.user_name}</td>
                              <td>{coin.ip}</td>
                           </tr>
                        ))
                        }
                           
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
    }
   </div>
</main>

            </div>
        )
    }
}
export default Mypanel;