import React, { Component } from 'react';

class About extends Component {

  render() {
    return (
      <div>
        <div class="whatIs">###The Faith Based Economy Has Arrived##</div>
          <div class="textAreaWhatIs flex">
            
            
         </div>
      </div>
    );
  }
}

export default About;
