import React, { Component } from 'react';
//import './css/App.css';
import './css/custom.css';
import './css/style.css';
import './css/responsive.css';
import './css/bootstrap.min.css';
import './css/animate.css';
import './css/export.css';
import './css/timeline.min.css';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

//import './css/font-awesome.min.css';
import Button from '@material-ui/core/Button';
import Start from './components/Start';
import About from './components/About';
import Whitepaper from './components/Whitepaper';
import Roadmap from './components/Roadmap';
import Contribute from './components/Contribute';

import Home from "./components/Home";

import Mypanel from "./components/Mypanel";
import Rollmaster from "./components/Rollmaster";
import Adduser from "./components/Adduser";
import Userlist from "./components/Userlist";
import Invite from "./components/Invite";
import Profile from "./components/Profile";
import Dashboard from "./components/Dashboard";
import Report from "./components/Report";
import Graphs from "./components/Graphs";
import Login from "./components/Login";


class App extends Component {



  render() {
    return (
      <>
      <Router>
    <div id="container">
      <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/mypanel" component={Mypanel} />
        <Route exact path="/rollmaster" component={Rollmaster} />
        <Route exact path="/adduser" component={Adduser} />
        <Route exact path="/userlist" component={Userlist} />
        <Route exact path="/invite" component={Invite} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/report" component={ Report } />
        <Route exact path="/graphs" component={ Graphs } />
        <Route exact path="/login" component={ Login } />
      
      </Switch>
    </div>
  </Router>
     

      </>
    );
  }
}

export default App;
