import React, {Component} from 'react';
import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';

import Chart from "react-apexcharts";

class Graphs extends Component {
    constructor(props){
        super(props)
        this.state = {
            coins: [],
            userName: '',
            userId: 0,
            userType:0,
            user:'',
            searchId:'',
            searchName:'',
            roleList: [],
            searchForm:'',
            searchTo:'',
            searchRole:'',
            options: {
                chart: {
                  id: "basic-bar"
                },
                xaxis: {
                  categories: ['unrestricted','restricted',  'Total']
                }
              },
              series: [
                {
                  name: "series-1",
                  data: []
                }
              ]
        };
        this.logOut             = this.logOut.bind(this);
        this.searchSubscription = this.searchSubscription.bind(this);
    }
    handlePdf = () => {
        const input = document.getElementById('page');
    
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'px', 'a4');
                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();
                console.log(height)
    
                pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
                pdf.save("report.pdf");
            });
    };

    searchSubscription(){
        console.log('hit..')
        var whereData = [];
        if(this.state.searchForm !='' && this.state.searchTo !=''){
            whereData.push({form: this.state.searchForm, to: this.state.searchTo });
        }
        if(this.state.searchRole !=''){
            whereData.push({role: this.state.searchRole});
        }

        fetch(`https://crearecoin.com/api/coin/search_subscription.php?form=${this.state.searchForm}&to=${this.state.searchTo}&role=${this.state.searchRole}`)
            .then(response => response.json())
            .then(data => {
                if(!data.error){
                    this.setState({coins: data.result});

                    var rcv   = 0;
                    var unrcv = 0;
                    for (let i = 0; i < data.result.length; i++) {
                        if(data.result[i].revCoin){
                            rcv += data.result[i].revCoin;
                        }                        
                        if(data.result[i].UnrevCoin){
                            unrcv += data.result[i].UnrevCoin;
                        }                        
                    }

                    var series = [{
                        name: "series-1",
                        data: [unrcv,rcv,(rcv+unrcv)]
                      }]
                    this.setState({series: series });
                }
                //
                console.log(data);
            });
        console.log(whereData);
    }
    
    getAllCoins(){
        setTimeout(() => {
            fetch(`
    https://crearecoin.com/api/coin/get_all_users.php`)
            .then(response => response.json())
            .then(data => {
                if(!data.error){
                    this.setState({coins: data.result});
                    var total = 0;
                    var rcv   = 0;
                    var unrcv = 1;
                    for (let i = 0; i < data.result.length; i++) {
                        if(data.result[i].revCoin){
                            rcv += data.result[i].revCoin;
                        }                        
                        if(data.result[i].UnrevCoin){
                            unrcv += data.result[i].UnrevCoin;
                        }                        
                    }

                    var series = [{
                        name: "series-1",
                        data: [unrcv,rcv,(rcv+unrcv)]
                      }]
                    this.setState({series: series });
                }
                //
                console.log(this.state.coins);
            });
        }, 200);
    }
    
    logOut(){
        console.log('logout')
        localStorage.removeItem("user");
        this.props.history.push({ 
            pathname: '/login'
        });
    }
    handleEdit(e){
        console.log(this.props)
        //this.props.history.push("adduser");
        this.props.history.push({ 
            pathname: '/adduser',
            state: e
        });
    }
    handleDelete(e){
        fetch(`
    https://crearecoin.com/api/coin/sign-up.php?delete=`+e.id)
            .then(response => response.json())
            .then(data => {
                this.getAllCoins();
            });
    }
    getAllRolle(){
        fetch(`
        https://crearecoin.com/api/coin/role-list.php`)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({roleList: data.result});
            });
    }
    componentDidMount() {
        console.log('hit ')
        let user =JSON.parse(localStorage.getItem('user'));
        if(!user || user=== null){
            window.location.replace('./');
        }
        this.getAllCoins();
        if(user){
            this.setState({userName: user.name});
            this.setState({userId: user.id});
            this.setState({userType: user.user_type});
            this.setState({user: user});
        }
        this.getAllRolle();
        
    }
  render(){
    const reportHeader = {
        border: "1px solid black",
      };
    return (
        
        <>
        <header class="menu-sticky">
             <div class="topnav" id="myTopnav">
             
             <a href="./"><img src='images/Creare-Coin_logo.png' /> </a>
             
             { this.state.userId != -1 && 
             <a class="log" onClick={this.logOut} style={{float:'right' } }>
                     Logout
             </a> }
             
             </div>
         </header>
         <div class="main-contain">
             <section class="iq-tranding-Platform trending-2">
                 <div class="container-floud">
                 <div class="row black-background">
                         <div class="col-sm-6">
                             <div class="heading-title">
                                 
                             </div>
                         </div>
                         <div class="col-sm-6">
                             <div class="heading-title header-test">
                                 <p>{this.state.user.address } &nbsp;&nbsp;
                                 || &nbsp;&nbsp;
                                 {this.state.user.balance } {this.state.user.symbol }
                                 </p>
                             </div>
                         </div>
                         
                     </div>
                     <div class="row">
                         <div className="col-sm-2 overview-block-pb">
                             <ul className="menu">
                                 <li><a href="/dashboard">DASHBOARD</a> </li>
                                 <li><a href="/rollmaster">User role master</a> </li>
                                 <li><a href="/adduser">Subscription Master</a> </li>
                                 <li><a href="/invite">Invite</a> </li>
                                 <li><a href="/report">Report & Graphs</a>
                                    <ul>
                                        <li><a href="/report">Report</a></li>
                                        <li><a href="/graphs">Graphs</a></li>
                                    </ul>
                                  </li>
                             </ul>
                         </div>
                         <div className="col-sm-10 white-background">
                         
                         <div class="table-responsive" id='page'>
                             <div style={reportHeader}>
                             <Chart
                                    options={this.state.options}
                                    series={this.state.series}
                                    type="bar"
                                    width="500"
                                    />
                                

                             </div>
                  
               </div>
                         </div>
                     </div>
                 </div>
             </section>
         </div>
        </>
    );
  }
}

export default Graphs;