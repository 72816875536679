import React from "react";
import { render } from "react-dom";

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            coins: [],
            userName: '',
            userId: 0,
            userType:0,
            user: '',
        };
        this.logOut = this.logOut.bind(this);
      }
      componentDidMount() {
        console.log('hit ')
        let user =JSON.parse(localStorage.getItem('user'));
        if(!user || user=== null){
            window.location.replace('./');
        }
        if(user){
            this.setState({userName: user.name});
            this.setState({userId: user.id});
            this.setState({userType: user.user_type});
            this.setState({user: user});
        }
        
        
        }

    logOut(){
        console.log('logout')
        localStorage.removeItem("user");
        this.props.history.push({ 
            pathname: '/login'
        });
    }
      render() {
        console.log(this.state);
        return (
            <div>
                <div class="ms-aside-overlay ms-overlay-right ms-toggler" 
                data-target="#ms-recent-activity" data-toggle="slideRight"></div>

                <div id="ms-side-nav" class="side-nav fixed ">
                <div class="logo-sn ms-d-block-lg">
                    <a class="pl-0 ml-0 text-center" href="./"> 
                    <img class="imggolgo" src="./shkl.jpeg" />
                     </a>
                    
                    <h5 class="text-center text-white mt-2">{this.state.userName} </h5>
                  
                </div>

                <ul class="accordion ms-main-aside fs-14" id="side-nav-accordion">
                    <li class="menu-item">
                        <a href="/mypanel" class="has-chevron" data-toggle="collapse" data-target="#dashboard" aria-expanded="false" aria-controls="dashboard">
                        <span><i class="material-icons fs-16">dashboard</i>Dashboard </span>
                        </a>
                        <ul id="dashboard" class="collapse" aria-labelledby="dashboard" data-parent="#side-nav-accordion">
                            <li> <a href="index.html">Medjestic</a> </li>
                        </ul>
                    </li>
                    {this.state.userType==1 &&
                    <li class="menu-item">
                        <a href="/rollmaster" class="has-chevron" data-toggle="collapse" data-target="#dashboard" aria-expanded="false" aria-controls="dashboard">
                        <span><i class="material-icons fs-16">dashboard</i>User role master</span>
                        </a>
                        <ul id="dashboard" class="collapse" aria-labelledby="dashboard" data-parent="#side-nav-accordion">
                            <li> <a href="index.html">Medjestic</a> </li>
                        </ul>
                    </li>
                     }
                     {this.state.userType==1 &&
                    <li class="menu-item">
                        <a href="/adduser" class="has-chevron" data-toggle="collapse" data-target="#dashboard" aria-expanded="false" aria-controls="dashboard">
                        <span><i class="material-icons fs-16">dashboard</i>Subscription Master</span>
                        </a>
                    </li>
                     }
                     {this.state.userType==1 | this.state.userType==2 &&
                    <li class="menu-item">
                        <a href="/invite" class="has-chevron" data-toggle="collapse" data-target="#dashboard" aria-expanded="false" aria-controls="dashboard">
                        <span><i class="material-icons fs-16">dashboard</i>Invite</span>
                        </a>
                    </li>
                     }  
                    {this.state.userType==3 &&
                    <li class="menu-item">
                        <a href="/profile" class="has-chevron" data-toggle="collapse" data-target="#dashboard" aria-expanded="false" aria-controls="dashboard">
                        <span><i class="material-icons fs-16">dashboard</i>Profile</span>
                        </a>
                    </li>
                     } 
                    
                </ul>
                </div>

                <main class="body-content">
   <nav class="navbar ms-navbar">
     <div class="ms-aside-toggler ms-toggler pl-0" data-target="#ms-side-nav" data-toggle="slideLeft">
       <span class="ms-toggler-bar bg-white"></span>
       <span class="ms-toggler-bar bg-white"></span>
       <span class="ms-toggler-bar bg-white"></span>
     </div>
     <div class="logo-sn logo-sm ms-d-block-sm">
       <a class="pl-0 ml-0 text-center navbar-brand mr-0" href="index.html">
           <img src="assets/img/medjestic-logo-84x41.png" alt="logo" /> </a>
     </div>
     <ul class="ms-nav-list ms-inline mb-0" id="ms-nav-options">
       <li class="ms-nav-item ms-nav-user dropdown">
         <a id="userDropdown" onClick={this.logOut} > 
         <i class="material-icons fs-16">exit_to_app</i>
          </a>
       </li>
     </ul>
     
   </nav>
   <div class="ms-content-wrapper">
      <div class="row">
         <div class="col-xl-12 col-md-12">
            <div class="ms-panel">
               <div class="ms-panel-header">
                  <h6>Profile </h6>
               </div>
               <div class="ms-panel-body">
                   
                   <div className="row big-fint">
                       <div className="col-sm-4">
                        <p>Name : <b>{this.state.user.fname } {this.state.user.lname }</b></p>
                        <p>Email :<b>{this.state.user.user_name } </b></p>
                        <p>Total No. of Coins :<b>{this.state.user.amount/this.state.user.priceCoin } </b></p>
                       </div>
                       <div className="col-sm-4">
                        <p>Phone :<b> {this.state.user.pnone }</b> </p>
                        <p>Country : <b>{this.state.user.country } </b></p>
                       </div>
                       <div className="col-sm-4">
                        <p>Symbol :<b> {this.state.user.symbol } </b></p>
                        <p>Investment amount :<b> {this.state.user.amount } </b></p>
                        <p>Price per Coin :<b> {this.state.user.priceCoin } </b></p>
                       </div>
                   </div>
                  
                  
                  
               </div>
            </div>
         </div>
      </div>
   </div>
</main>

            </div>

        );
      }

}
export default Profile;