import React, { Component } from 'react';
import Button from "react-bootstrap/Button";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import KaspTokens from "../contracts/KaspTokens.json";
import KaspTokenSale from "../contracts/KaspTokenSale.json";
import getWeb3 from "../getWeb3";


import './app.min.css';
// https://themesbrand.com/dason/layouts/default/tables-basic.html

class Adduser extends Component{
    constructor(props){
        super(props)
        this.state = {
            coins: [],
            userName: '',
            userId: 0,
            userType:0,
            role:'',
            roleDes:'',
            email:'',
            password: '',
            login: true,

            fname: '',
            lname: '',
            com_name: '',
            con_per: '',
            country:'',
            age: '',
            phonecode:'',
            pnone: '',
            gender: '',
            coinName: '',
            symbol: 'SHKL',
            coin_des: '',
            treas: '',
            roles: [],
            role:'',
            countrys: [],
            uid:0,
            dob: '',
            roleDetails:'',
            amount:'',
            priceCoin:'',
            showSuccessAlert: false,
            showErrorAlert: false,
            usr:'',
            emailError: false,
            byeAmt:0,
            user:'',
        };
        this.handleDobChange = this.handleDobChange.bind(this);
        this.getRole = this.getRole.bind(this);
        this.logOut = this.logOut.bind(this);
        this.roleHendl = this.roleHendl.bind(this);
        this.roleDesHendel = this.roleDesHendel.bind(this);
        this.submitRole = this.submitRole.bind(this);


        this.userSignup            = this.userSignup.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleEmailChange    = this.handleEmailChange.bind(this);
        this.handleNameChange     = this.handleNameChange.bind(this);
        this.handleCompanyChange        = this.handleCompanyChange.bind(this);
        this.handleContactPerChange        = this.handleContactPerChange.bind(this);
        this.handleAgeChange        = this.handleAgeChange.bind(this);
        this.handlePhoneChange        = this.handlePhoneChange.bind(this);
        this.handleGenderChange        = this.handleGenderChange.bind(this);
        this.handleCoinNameChange        = this.handleCoinNameChange.bind(this);
        this.handleSymbolChange        = this.handleSymbolChange.bind(this);
        this.handleCoinDesChange        = this.handleCoinDesChange.bind(this);
        this.handleTreasChange        = this.handleTreasChange.bind(this);
        this.handleRoleChange        = this.handleRoleChange.bind(this);
        this.handleChangeAmount        = this.handleChangeAmount.bind(this);
        this.handleChangePriceCoin        = this.handleChangePriceCoin.bind(this);
        this.byeCoin                        = this.byeCoin.bind(this);
    }


    handleRoleChange(e) {
        let role =  e.target.value;
        this.setState({role: e.target.value});
        fetch(`
    https://crearecoin.com/api/coin/get_role.php?name=${role}`)
            .then(response => response.json())
            .then(data => {
                var res = data.result;
                console.log(res);
                this.setState({roleDetails: res[0]});
                console.log(this.state.roleDetails);
            });
    }
    handleChangePriceCoin(e) {

        this.setState({priceCoin: e.target.value});
        let investAmt = Number(this.state.amount)/Number(e.target.value);
        this.setState({byeAmt: investAmt}); 
     }
    handleChangeAmount(e) {
        this.setState({amount: e.target.value});
     }
    handleCompanyChange(e) {
        this.setState({com_name: e.target.value});
     }
     handleDobChange(e) {
        this.setState({dob: e.target.value});
        var d = new Date(e.target.value);
        let y = d.getFullYear();
        let d2 = new Date();
        let y2 = d2.getFullYear();
        let age = (y2-y);
        this.setState({age: age});
     }
     handleContactPerChange(e) {
        this.setState({con_per: e.target.value});
     }
     handleAgeChange(e) {
        this.setState({age: e.target.value});
     }
     handlePhoneChange(e) {
        this.setState({pnone: e.target.value});
     }
     handleGenderChange(e) {
        this.setState({gender: e.target.value});
     }
     handleCoinNameChange(e) {
        this.setState({coinName: e.target.value});
     }
     handleSymbolChange(e) {
        this.setState({symbol: e.target.value});
     }
     handleCoinDesChange(e) {
        this.setState({coin_des: e.target.value});
     }
     handleTreasChange(e) {
        this.setState({treas: e.target.value});
     }


      handleEmailChange(e) {
          var emailId = e.target.value;
          fetch(`https://crearecoin.com/api/coin/check-user-email.php?email=`+emailId)
          .then(res => res.json())
          .then(data =>{
              if(data.rows >0){
                  this.setState({emailError: true});
              }
              console.log(data);
          })
        this.setState({email: e.target.value});
     }
     handleNameChange(e) {
        this.setState({name: e.target.value});
     }
     handlePasswordChange(e) {
        this.setState({password: e.target.value});
     }

    roleHendl(e){
        this.setState({role: e.target.value});
    }
    roleDesHendel(e){
        this.setState({roleDes: e.target.value});
    }
    getRole(){
        fetch(`
    https://crearecoin.com/api/coin/get_role.php`)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({roles: data.result});
            });
    }
    getCountrys(){
        fetch(`
    https://crearecoin.com/api/coin/get_all_countrys.php`)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({countrys: data.result});
            });
    }
    userSignup() {
        console.log(this.state)
        fetch(`
  https://crearecoin.com/api/coin/sign-up.php?
  fname=${this.state.fname}&lname=${this.state.lname}&com_name=${this.state.com_name}
  &country=${this.state.country}&symbol=${this.state.symbol}&con_per=${this.state.con_per}
  &age=${this.state.age}&phonecode=${this.state.phonecode}&pnone=${this.state.pnone}
  &gender=${this.state.gender}&role=${this.state.role}&amount=${this.state.amount}
  &priceCoin=${this.state.priceCoin}&id=${this.state.uid}&email=${this.state.email}&password=${this.state.password}
  &usr=${this.state.usr}&date_=${this.state.dob}
  `)
          .then(response => response.json())
          .then(data => {
              if(!data.error){
                this.setState({showSuccessAlert: true})
                this.setState({fname: ''});this.setState({lname: ''});this.setState({com_name: ''});
                this.setState({country: ''});this.setState({symbol: ''});this.setState({con_per: ''});
                this.setState({age: ''});this.setState({phonecode: ''});this.setState({pnone: ''});
                this.setState({gender: ''});this.setState({role: ''});this.setState({amount: ''});
                this.setState({priceCoin: ''});this.setState({email: ''});this.setState({password: ''});
                this.setState({usr: ''});

              }else{
                this.setState({showErrorAlert: true})
              }

              if(this.state.uid !=0){
                this.setState({uid: 0});
                this.props.history.push({ 
                    pathname: '/userlist'
                });
              }
            //this.setState({uid: 0});
          });

    }

    submitRole(){
        console.log(this.state);
        fetch(`
    https://crearecoin.com/api/coin/role.php?
    name=${this.state.role}&des=${this.state.roleDes}`)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({role: ''});
                this.setState({roleDes: ''});
            });
    }

    getAllCoins(){
        setTimeout(() => {
            fetch(`
    https://crearecoin.com/api/coin/get_all_coins.php?id=`+this.state.userId+`&type=`+this.state.userType)
            .then(response => response.json())
            .then(data => {
                if(!data.error){
                    this.setState({coins: data.result});
                }
                //
                console.log(this.state.coins);
            });
        }, 200);
    }


    async loadBlockChain() {
        const web3 = await getWeb3();
        //const accounts = web3.eth.getAccounts().then();
        const accounts = await web3.eth.getAccounts();
        this.setState({address: accounts});
        const networkId = await web3.eth.net.getId();
        const KaspTokensInstance = new web3.eth.Contract(
          KaspTokens.abi,
          KaspTokens.networks[networkId] &&
            KaspTokens.networks[networkId].address
        );
        console.log(KaspTokensInstance);
    
        const KaspTokenSaleInstance = new web3.eth.Contract(
          KaspTokenSale.abi,
          KaspTokenSale.networks[networkId] &&
            KaspTokenSale.networks[networkId].address
        );
        this.setState({KaspTokenSaleInstance: KaspTokenSaleInstance});
    
        const balance = await KaspTokensInstance.methods
              .balanceOf(accounts[0])
              .call();
              console.log(balance);
              this.setState({balance: balance});
    
            const symbol = await KaspTokensInstance.methods.symbol().call(); // symbol
            this.setState({symbol: symbol});
    
            const to_name = await KaspTokensInstance.methods.name().call();
    
            const tokenSold = await KaspTokenSaleInstance.methods
              .tokenSold()
              .call();
              this.setState({tokenSold: tokenSold});
            const available = await KaspTokenSaleInstance.methods
              .tokensLeft()
              .call();
              
            const sum = ~~available + ~~tokenSold;
            this.setState({available: sum});
            const tokenprice = await KaspTokenSaleInstance.methods
              .tokenprice()
              .call();
              console.log(tokenSold);
              this.setState({tokenprice: tokenprice});
    
    
      }
      byeCoin(coin){
          console.log(coin);
          let con = Math.floor( coin );
            con = Number( con );
          console.log(con);
          let res = this.state.KaspTokenSaleInstance.methods.buyTokens(con).send({
             from: this.state.address[0],
             value: con * this.state.tokenprice
           });
      }
      handleOnSubmit = async () => {
        console.log('click')
        let num = Number(this.state.byeAmt);
        console.log(num)
        console.log( num * this.state.tokenprice)
        console.log(this.state.address[0])
        let res = this.state.KaspTokenSaleInstance.methods.buyTokens(num).send({
           //let res = await KaspTokenSaleInstance.methods.buyTokens(obj.amount).send({
            from: this.state.address[0],
            value: num * this.state.tokenprice
          });
      };

    componentDidMount() {
        this.loadBlockChain()
        console.log('hit ')
        console.log(this.props.location.state)
        console.log(this.props.location.state)
        /* this.setState(
                {
                    age: this.props.location.state.age,
                    coinName: this.props.location.state.coi nName
                }) */

        if(this.props.location.state){
            //if()
            console.log(this.props.location.state)
             this.setState(
                {
                    age: this.props.location.state.age,
                    coinName: this.props.location.state.coinName,
                    coin_des: this.props.location.state.coin_des,
                    com_name: this.props.location.state.com_name,
                    con_per: this.props.location.state.con_per,
                    email: this.props.location.state.user_name,
                    gender: this.props.location.state.gender,
                    name: this.props.location.state.name ,
                    pnone: this.props.location.state.pnone ,
                    symbol: "SHKL",
                    treas: this.props.location.state.treas ,
                    uid:this.props.location.state.id,
                    fname:this.props.location.state.fname,
                    lname:this.props.location.state.lname,
                    usr:this.props.location.state.usr,
                    amount:this.props.location.state.amount,
                    priceCoin:this.props.location.state.priceCoin,
                    country:this.props.location.state.country,
                    phonecode:this.props.location.state.phonecode,
                    role:this.props.location.state.role,
                    roleDetails: this.props.location.state.rows,
                    dob: this.props.location.state.date_,
                }
                );
                 
                //this.setGender(this.state.usr)
                setTimeout(() => {
                    console.log(this.state)
                }, 100);
                

        }
        
        console.log('hit end')
        this.getRole();
        let user =JSON.parse(localStorage.getItem('user'));
        if(!user || user=== null){
            window.location.replace('./');
        }
        this.getAllCoins();
        if(user){
            this.setState({userName: user.name});
            this.setState({userId: user.id});
            this.setState({userType: user.user_type});
            this.setState({user: user});
        }
        this.getCountrys();
        
    }
    logOut(){
        console.log('logout')
        localStorage.removeItem("user");
        this.props.history.push({ 
            pathname: '/login'
        });
    }
    notify(){
        console.log('hit show')
        console.log(toast)
        toast('Hello Geeks 5',
       {position: toast.POSITION.BOTTOM_CENTER})
    }
    setGender(event) {
        this.setState({usr: event.target.value})
        /* if(event.target.value=='am'){

        }else{
            
        }
        if(event.target.value=='inv'){

        }else{

        } */
    }

    render(){
        return(
            <>
            <header class="menu-sticky">
                <div class="topnav" id="myTopnav">
                
                <a href="./"><img src='images/Creare-Coin_logo.png' /> </a>
                
                { this.state.userId != -1 && 
                <a class="log" onClick={this.logOut} style={{float:'right' } }>
                        Logout
                </a> }
                
                </div>
            </header>
            <div class="main-contain">
                <section class="iq-tranding-Platform trending-2">
                    <div class="container-floud">
                    <div class="row black-background">
                            <div class="col-sm-6">
                                <div class="heading-title">
                                    
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="heading-title header-test">
                                    <p>{this.state.user.address } &nbsp;&nbsp;
                                    || &nbsp;&nbsp;
                                    {this.state.user.balance } {this.state.user.symbol }
                                    </p>
                                </div>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div className="col-sm-2 overview-block-pb">
                                <ul className="menu">
                                    <li><a href="/dashboard">DASHBOARD</a> </li>
                                    <li><a href="/rollmaster">User role master</a> </li>
                                    <li><a href="/adduser">Subscription Master</a> </li>
                                    <li><a href="/invite">Invite</a> </li>
                                    
                                    <li><a href="/report">Reports & Graphs</a> </li>
                                </ul>
                            </div>
                            <div className="col-sm-10 white-background">
                            <div class="row">
         <div class="col-xl-12 col-md-12">
            <div class="ms-panel">
               <div class="ms-panel-header">
                  <h6>Subscription Master
                  </h6>
                    {this.state.userType ==1 && 
                  <a type="button" class="btn btn-success listbtn"
                  href="/userlist"
                  >List</a> }

               </div>
               <div class="ms-panel-body">
                   <div class="row">
                       <div class='col-sm-12'>
                           {this.state.showSuccessAlert &&
                            <div class="alert alert-success" role="alert" 
                            onClick={(e)=>this.setState({showSuccessAlert: false})}>
                                Subscription added successfully
                            </div>
                            }
                            {this.state.showErrorAlert &&
                            <div class="alert alert-danger" role="alert"
                            onClick={(e)=>this.setState({showErrorAlert: false})}>
                                Something was wrong!!
                            </div>
                             }
                       </div>
                   </div>
               <div class="row">
                                <div class="form-group col-sm-4">
                                    <label for="username" class="text-info">First Name:</label><br/>
                                    <input type="text" name="name" class="form-control" 
                                    value={this.state.fname}
                                    onChange={(e)=>this.setState({fname: e.target.value})}
                                    />
                                </div>
                                <div class="form-group col-sm-4">
                                    <label for="username" class="text-info">Last Name:</label><br/>
                                    <input type="text" name="lname" class="form-control" 
                                    value={this.state.lname}
                                    onChange={(e)=>this.setState({lname: e.target.value}) }
                                    />
                                </div>
                                <div class="form-group col-sm-4">
                                    <label for="username" class="text-info">Company Name:</label><br/>
                                    <input type="text" name="company_name" class="form-control" 
                                    value={this.state.com_name}
                                    onChange={this.handleCompanyChange}
                                    />
                                </div>
                                </div>
                                <div class="row">
                                <div class="form-group col-sm-6">
                                    <label for="username" class="text-info">Country: </label><br/>
                                   <select class="form-control" name='country' 
                                   value={this.state.country}
                                   onChange={(e)=>this.setState({country: e.target.value})}
                                   >
                                       <option value="">Select</option>
                                       { this.state.countrys &&
                                                this.state.countrys.map((coin, index) => (
                                                <option key={index}
                                                value={coin.name}
                                                >
                                                    {coin.name}
                                                </option>
                                                ))
                                                }
                                    </select>
                                </div>
                                <div class="form-group col-sm-6">
                                        <label for="username" class="text-info">Symbol :</label><br/>
                                        <input type="text" name="symbol" class="form-control" 
                                        value={this.state.symbol}
                                        onChange={this.handleSymbolChange}
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-sm-6">
                                        <label for="username" class="text-info">Contact person:</label><br/>
                                        <input type="text" name="contect_per" class="form-control" 
                                        value={this.state.con_per}
                                        onChange={this.handleContactPerChange}
                                        />
                                    </div>
                                    
                                    <div class="form-group col-sm-3">
                                        <label for="username" class="text-info">Date of Birth:</label><br/>
                                        <input type="date" name="age" class="form-control" 
                                        value={this.state.dob}
                                        onChange={this.handleDobChange}
                                        />
                                    </div>
                                    <div class="form-group col-sm-3">
                                        <label for="username" class="text-info">Age:</label><br/>
                                        <input type="text" name="age" class="form-control" 
                                        value={this.state.age}
                                        onChange={this.handleAgeChange}
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-sm-6">
                                        <label for="username" class="text-info">Phone:</label><br/>
                                        <select class="form-control count-select"
                                        value={this.state.phonecode}
                                        onChange={(e)=>this.setState({phonecode: e.target.value})}
                                         >
                                        <option value="">Select</option>
                                        { this.state.countrys &&
                                                    this.state.countrys.map((coin, index) => (
                                                    <option key={index}
                                                    value={coin.phonecode}
                                                    >
                                                        ( +{coin.phonecode} )
                                                        {coin.name}
                                                    </option>
                                                    ))
                                                    }
                                        </select>
                                         <input type="text" name="phone" class="form-control phone" 
                                        value={this.state.pnone}
                                        onChange={this.handlePhoneChange}
                                        />
                                        
                                    </div>
                                    <div class="form-group col-sm-3">
                                        <label for="username" class="text-info">Gender:</label><br/>
                                        <select class="form-control" 
                                        value={this.state.gender} 
                                        onChange={this.handleGenderChange}>
                                            <option value="">Select</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                            
                                        </select>
                                    </div>
                                    <div class="form-group col-sm-3">
                                        <br />
                                        <div onChange={this.setGender.bind(this)}>
                                        <label class="text-info">
                                            <input type="radio" value='am' name='usr'
                                            checked={this.state.usr =='am'}
                                             /> &nbsp;
                                            Ambassador
                                        </label>
                                        <label class="text-info">
                                            <input type="radio" value='inv' name='usr' 
                                            checked={this.state.usr =='inv'}
                                            /> &nbsp;
                                            Investor
                                        </label>
                                        </div>
                                    </div>
                                </div>
                                { this.state.usr=='am'&&  
                                <div class="row">
                                    <div class="form-group col-sm-6">
                                        <label for="username" class="text-info">Role :</label><br/>
                                        <select class="form-control" 
                                        value={this.state.role} 
                                        onChange={this.handleRoleChange}
                                        >
                                            <option value="">Select</option>
                                            { this.state.roles &&
                                                this.state.roles.map((coin, index) => (
                                                <option key={index}
                                                value={coin.name}
                                                >
                                                    {coin.name}
                                                </option>
                                                ))
                                                }
                                        </select>
                                    </div>
                                    <div class="col-sm-6 role-box">
                                        <table className="table table-bordered table-hover"id="tab_logic">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Percentage</th>
                                                    <th className="text-center">Coin</th>
                                                    <th className="text-center">Holding period (days)</th>
                                                    <th>Action </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.roleDetails && this.state.roleDetails.rows &&
                                            <>
                                            {this.state.roleDetails.rows.map((row, idx)=>( 
                                                <tr  key={idx}>
                                                    <td>{ row.perc }</td>
                                                    <td>{row.coin } </td>
                                                    <td>{row.days }</td>
                                                    <td>{
                                                        new Date(row.days).getTime() <= new Date().getTime() &&
                                                        
                                                        <button type='button'
                                                        class=' btn-success btn-ruds'
                                                        onClick={() => this.byeCoin(row.coin)}
                                                        > Buy & Transfer
                                                        </button>
                                                        }

                                                        {
                                                        new Date(row.days).getTime() >= new Date().getTime() &&
                                                        <button type='button'
                                                        class=' btn-info btn-ruds'
                                                        > Buy & Transfer
                                                        </button>
                                                        }
                                                    </td>
                                                </tr>
                                            )) }
                                            </>
                                            }
                                            </tbody>
                                        </table>
                                    </div>


                                </div>
                                }

{ this.state.usr=='inv'&&
                                <>
                                <div class='row'>
                                    <div class='col-sm-4'>
                                        <div class="form-group">
                                            <label for="username" class="text-info">Investment Amount($):</label><br/>
                                            <input type="text" name="amount" class="form-control" 
                                            value={this.state.amount}
                                            onChange={this.handleChangeAmount}
                                            />
                                        </div>
                                    </div>
                                    <div class='col-sm-4'>
                                        <div class="form-group">
                                        <label for="username" class="text-info">Price per Coin ($) :</label><br/>
                                        <input type="text" name="priceCoin" class="form-control" 
                                        value={this.state.priceCoin}
                                        onChange={this.handleChangePriceCoin}
                                        />
                                    </div>
                                    </div>

                                    <div class='col-sm-4'>
                                        <div class="form-group">
                                        <button type='button' onClick={this.handleOnSubmit} class='btn btn-success'>Buy</button>
                                    </div>
                                    </div>
                                </div>
                                 
                                </> }

                                <div class="form-group">
                                    <label for="username" class="text-info">Email:</label><br/>
                                    <input type="email" name="username" id="username" class="form-control" 
                                    value={this.state.email}
                                    onChange={this.handleEmailChange}
                                    />
                                    {this.state.emailError &&
                                    <p class="text-danger">This email id already exists</p>
                                    }
                                </div>
                                <div class="form-group">
                                    <label for="password" class="text-info">Password:</label><br/>
                                    <input type="password" name="password" id="password" class="form-control" 
                                    value={this.state.password} 
                                    onChange={this.handlePasswordChange}
                                    />
                                </div>
                                {!this.state.emailError &&
                                <div class="form-group">
                                    {this.state.uid ==0 &&
                                    <Button onClick={this.userSignup}>Sign up</Button>
                                    }
                                    {this.state.uid > 0 &&
                                    <Button onClick={this.userSignup}>Update user</Button>
                                    }
                                    
                                </div>
                                }
                                
               </div>
            </div>
         </div>
      </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            </>
        )
    }
}
export default Adduser;