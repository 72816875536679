import React, {Component} from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';

import KaspTokens from "../contracts/KaspTokens.json";
import KaspTokenSale from "../contracts/KaspTokenSale.json";
import getWeb3 from "../getWeb3";

import MyAlgoConnect from '@randlabs/myalgo-connect';
import algosdk from "algosdk";
const myAlgoWallet = new MyAlgoConnect();

class Home extends Component {
    constructor(props){
        super(props)
        this.state = {
            name: '',
            email:'',
            phone: '',
            query:'',
            address:'',
            userId:'',
            KaspTokenSaleInstance:'',
            balance:'',
            symbol:'',
            tokenSold:'',
            available:'',
            tokenprice:'',
            user:'',
            amt:''
        }
        this.subMitQuery = this.subMitQuery.bind(this)
        this.byeCoin = this.byeCoin.bind(this)
    }

    byeCoin(){
        var coin = this.state.amt 
        console.log(coin);
        let con = Math.floor( coin );
          con = Number( con );
        console.log(con);
        let res = this.state.KaspTokenSaleInstance.methods.buyTokens(con).send({
           from: this.state.address[0],
           value: con * this.state.tokenprice
         });
    }
    async connectToMyAlgo() {
        try {
          const accounts = await myAlgoWallet.connect();
          const addresses = accounts.map(account => account.address);
          console.log(accounts)
          console.log(addresses)
        } catch (err) {
          console.error(err);
        }
      }
    async loadBlockChain() {
        console.log('loadBlockChain');
        const web3 = await getWeb3();
        console.log(web3)
        //const accounts = web3.eth.getAccounts().then();
        const accounts = await web3.eth.getAccounts();
        console.log(accounts);
        this.setState({address: accounts});
        
        const networkId = await web3.eth.net.getId();
        const KaspTokensInstance = new web3.eth.Contract(
          KaspTokens.abi,
          KaspTokens.networks[networkId] &&
            KaspTokens.networks[networkId].address
        );
        console.log(KaspTokensInstance);
    
        const KaspTokenSaleInstance = new web3.eth.Contract(
          KaspTokenSale.abi,
          KaspTokenSale.networks[networkId] &&
            KaspTokenSale.networks[networkId].address
        );
        this.setState({KaspTokenSaleInstance: KaspTokenSaleInstance});
    
        const balance = await KaspTokensInstance.methods
              .balanceOf(accounts[0])
              .call();
              console.log(balance);
              this.setState({balance: balance});
    
            const symbol = await KaspTokensInstance.methods.symbol().call(); // symbol
            this.setState({symbol: symbol});
    
            const to_name = await KaspTokensInstance.methods.name().call();
    
            const tokenSold = await KaspTokenSaleInstance.methods
              .tokenSold()
              .call();
              this.setState({tokenSold: tokenSold});
            const available = await KaspTokenSaleInstance.methods
              .tokensLeft()
              .call();
              
            const sum = ~~available + ~~tokenSold;
            this.setState({available: sum});
            const tokenprice = await KaspTokenSaleInstance.methods
              .tokenprice()
              .call();
              console.log(tokenSold);
              this.setState({tokenprice: tokenprice});
    
    
      }
    subMitQuery(){
        console.log(this.state);
        fetch(`
    https://crearecoin.com/api/coin/query.php?name=${this.state.name}&email=${this.state.email}
    &phone=${this.state.phone}&query=${this.state.query}`)
            .then(response => response.json())
            .then(data => {
                //this.getAllRolle();
                this.setState({name: '' })
                this.setState({email: '' })
                this.setState({phone: '' })
                this.setState({query: '' })
            });
    }

    componentDidMount(){
        this.loadBlockChain()
    }
    
  render(){
    return (
      <>
       <div>
    <header>
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                <Navbar collapseOnSelect expand="lg" bg="transparent" variant="dark">
                <Container>
                <Navbar.Brand href="#home">
                    <img src="images/Creare-Coin_logo.png" class="img-fluid" alt="" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                   
                    </Nav>
                    <Nav>
                    <Nav.Link href="#home">Home</Nav.Link>
                    <Nav.Link href="#ico">About us</Nav.Link>
                    <Nav.Link href="#product">Tokenomics</Nav.Link>
                    <Nav.Link href="#nft">NFT</Nav.Link>
                    <Nav.Link href="/report">Report</Nav.Link>
                    <Nav.Link href="#contact">Contact Us</Nav.Link>
                    <Nav.Link href="/login" className='nav-link button'>Login</Nav.Link>
                    
                    </Nav>
                </Navbar.Collapse>
                </Container>
                </Navbar>
                <div>
                <div class="myAccountBox">
                    <div class="address"> {this.state.address[0] } </div>
                        <div class="eth"> { this.state.symbol } : {this.state.balance } </div>
                        <div class="dctoken"> Total coin: { this.state.available } </div>
                        {/* <div class="dctoken"> Restricted  coin: { (this.state.available*60)/100 } |   
                        Unrestricted  coin: { (this.state.available*40)/100 }</div> */}
                    </div>
                </div>
                    {/* <nav class="navbar navbar-expand-lg navbar-light">
                        <a class="navbar-brand" href="javascript:void(0)">
                            <img src="images/Creare-Coin_logo.png" class="img-fluid" alt="" />
                        </a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i class="la la-bars"></i>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav mr-auto w-100 justify-content-end">
                                <li class="nav-item">
                                    <a class="nav-link active" href="#home">Home</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#ico">About us</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#product">Tokenomics</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#nft">NFT</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="/report">Report</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#contact">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                        <ul class="nav justify-content-end">
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    English
                                </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a class="dropdown-item" href="javascript:void(0)">Deutsch</a>
                                    <a class="dropdown-item" href="javascript:void(0)">Español</a>
                                    <a class="dropdown-item" href="javascript:void(0)">Spanish</a>
                                </div>
                            </li>

                            <li class="nav-item iq-mlr-0">
                                <a class="nav-link button"  href="/login">Login</a>
                            </li>
                        </ul>
                    </nav> */}
                </div>
            </div>
        </div>
    </header>
    <div id="home" class="iq-banner-2">
        <div id="particles-js"></div>
        <div class="banner-info">
            <div class="container">
                <div class="row justify-content-md-center">
                    <div class="col-lg-4">
                        <div class="justify-content-md-center">
                            <img class="center" src="images/Creare-Coin_logo(bg).png" alt="" />
                        </div>
                    </div>

                    <div class="col-lg-8">
                        <div class="banner-text iq-font-white">
                            <h1 class="iq-font-white iq-tw-7 iq-mb-20">CREARE COIN</h1>
                            <p class="lead">
                                Marketplace | MetaVerse | NFT | Coin | Cross-border Fiat <br />
                                <br />
                                Creare from the latin Creatus, past participate of creare to bring into being, beget, give birth to, cause to grow, causative from the base of crescere, to come into existence, increase in the size of the
                                number.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="creareCoin" class="iq-banner-2">
        <div id="particles-js"></div>
        <div class="banner-info">
            <div class="container">
                <div class="row justify-content-md-center">
                    

                    <div class="col-lg-4">
                        <br />
                        <br />
                        <br />
                        <p> Total coin: 20000000000 </p>
                        <p>Restricted  coin: 15000000000 </p>
                        <p>Unrestricted  coin: 5000000000 </p>
                    </div>
                    <div class="col-lg-8">
                        <div class="banner-text iq-font-white">
                            <h1 class="iq-font-white iq-tw-7 iq-mb-20">Creare Coin PreSale</h1>
                            <div class="input-group mb-3">
                                
                                <input type="text" class="form-control" placeholder="" aria-label="" 
                                aria-describedby="basic-addon1"
                                value={this.state.amt}
                                onChange={(e)=>{this.setState({amt: e.target.value}) } }
                                 />
                                <div class="input-group-prepend">
                                    <button class="btn btn-secondary " type="button"
                                    onClick={this.byeCoin}
                                    >Buy</button>
                                </div>
                                </div>
                        </div>
                        <button onClick={this.connectToMyAlgo} className="btn btn-warning">Connect!</button>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="main-contain">
        <div class="iq-our-clients iq-ptb-60 light-bg">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <img class="center" src="images/Creare-Coin_logo(bg).png" alt="" width="150" height="150" />
                    </div>
                </div>
                <div id="product" class="col-sm-12">
                    <div class="heading-title">
                        <h2 class="title iq-tw-6">CREARE COIN - TOKENOMICS</h2>
                        <p>ENABLES COMMUNITIES FOR SELF-SUSTAINING ECONOMIES</p>
                        <p>( FROM CROSS-BORDER TO THE METAVERSE)</p>
                        <p>SEAMLESS TRANSACTIONS FOR BOTH BRICK & MORTAR, E-COMMERCE & THE VIRTUAL REALM!</p>
                        <p class="text-coin">MAX SUPPLY: 20 BILLION</p>
                    </div>
                </div>

                <img class="smartcard" src="images/smartcard-logo.png" alt="" />
            </div>
        </div>
    </div>
    <section id="ico" class="overview-block-ptb">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="crypto-coin">
                        <h1 class="hr-text">CREARE COIN</h1>
                    </div>
                    <hr class="divider" />
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <p class="crypto-coin-text">
                        Creare Coin is a crypto coin that is launched with a mandate to enable communities with a self-sustaining economy. A metaverse that gives a seamless transactional utility to multi-industry in a digital realm.<br />
                        <br />

                        The design of the coin incorporates a multi-tenant multi-industry solution Tokenomics - allowing onboarding merchants a marketplace where they upload their products, services & offers. The community members use their
                        coins to trade, exchange or redeem to purchase or remit. Further to it, the merchant engages with the customers through various methods of engagement i.e., Loyalty reward programs, Retail, Egaming & Esports solutions
                        assisting growth in their reach.<br />
                        <br />

                        Creare also facilitates Industries to create & introduce their own Coin, Marketplace, NFT & engagement tools with the Intellectual property & SaaS solutions.
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section class="overview-block-pb">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="heading-titlees">
                        <h1 class="hr-text2">CREARE COIN</h1>
                    </div>
                    <hr class="divider" />
                </div>
            </div>
            <div class="col-sm-12">
                <img src="images/background2.png" alt="" />
            </div>
        </div>
    </section>
    <section class="overview-block-ptb">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="heading-titles">
                        <h1 class="hr-text2">CREARE COIN</h1>
                    </div>
                    <hr class="divider" />
                    <img src="images/FEATURES_img.png" alt="" />
                </div>

                <div class="col-lg-7">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-5 sect">
                                <img src="images/icon/Picture2.png" alt="" class="imgs-cont" />
                                <h5 class="card-title">COIN</h5>
                                <p class="card-text">CREATION & ISSUANCE TO INVESTORS, INFLUENCERS, CUSTOMERS & ENTERPRISES.</p>
                            </div>

                            <div class="col-lg-5 FEATUR_sect sect">
                                <img src="images/icon/Picture3.png" alt="" class="imgs-cont" />
                                <h5 class="card-title">NFT</h5>
                                <p class="card-text">CREATION, MINTING, PUBLISHING, DISTRIBUTION, & SELLING.</p>
                            </div>

                            <div class="col-lg-5 sect">
                                <img src="images/icon/Picture5.png" alt="" class="imgs-cont" />
                                <h5 class="card-title">Marketplace & ECOMMERCE</h5>
                                <p class="card-text">MERCHANT ONBOARDING, VETTING, EKYC, PRODUCT & SERVICES OFFERING.</p>
                            </div>

                            <div class="col-lg-5 FEATUR_sect sect">
                                <img src="images/icon/Picture7.png" alt="" class="imgs-cont" />
                                <h5 class="card-title">CROSSBORDER</h5>
                                <p class="card-text">FX TRADE AND EXCHANGE IN 200+ COUNTRIES, TERRITORIES & DIGITL REALMS, AML, EKYC, EKYB.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="overview-block-ptb light-bg">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="heading-title">
                        <h1 class="title iq-tw-6 title-cn">COIN - ISSUANCE, DISTRIBUTION, EXCHANGE</h1>
                        <hr class="divider" />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <img class="info-1" src="images/Picture8.png" alt="" />
                </div>
            </div>
        </div>
    </section>

    <section class="overview-block-ptb">
        <div id="nft" class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="heading-title">
                        <h1 class="title iq-tw-6 title-con">NFT - MINTING, PUBLISHING, ISSUANCE & TRADING</h1>
                        <hr class="divider" />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <img class="info-1" src="images/Picture9.png" alt="" />
                </div>
            </div>
        </div>
    </section>

    <section id="ico" class="overview-block-ptb light-bg">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="crypto-coin">
                        <h1 class="hr-text">CREARE COIN</h1>
                    </div>
                    <hr class="divider" />
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <p class="crypto-coin-text">
                        Creare Coin is a crypto coin that is launched with a mandate to enable communities with a self-sustaining economy. A metaverse that gives a seamless transactional utility to multi-industry in a digital realm.<br />
                        <br />

                        The design of the coin incorporates a multi-tenant multi-industry solution Tokenomics - allowing onboarding merchants a marketplace where they upload their products, services & offers. The community members use their
                        coins to trade, exchange or redeem to purchase or remit. Further to it, the merchant engages with the customers through various methods of engagement i.e., Loyalty reward programs, Retail, Egaming & Esports solutions
                        assisting growth in their reach.<br />
                        <br />

                        Creare also facilitates Industries to create & introduce their own Coin, Marketplace, NFT & engagement tools with the Intellectual property & SaaS solutions.
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section class="overview-block-ptb">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="heading-titles">
                        <h1 class="title iq-tw-6 title-con">COIN DISTRIBUTION</h1>
                        <hr class="divider" />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <img class="info-1" src="images/Picture10.png" alt="" />
                </div>
            </div>
        </div>
    </section>
</div>

<footer id="contact" class="iq-footer1 black-bg overview-block-pt iq-bg-over iq-over-black-20">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-12 iq-mtb-10">
                <div class="logo">
                    <img src="images/Creare-Coin_logo(bg).png" alt="#" />
                    <div class="iq-font-white iq-mt-15">
                        <p>email:- sales@emphasispay.com</p>
                        <p>call:- 1-844-843-7296</p>
                    </div>
                    <ul class="info-share">
                        <li>
                            <a href="javascript:void(0)">
                                <img src='images/facebook.png' />
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                            <img src='images/twitter.png' />
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                            <img src='images/pinterest.png' />
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                            <img src='images/linkedin.png' />
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                            <img src='images/youtube.png' />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-sm-12 iq-mtb-10 about-us r9-mt-40">
                <h5 class="small-title iq-tw-6 iq-font-white iq-mb-30">About Us</h5>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <ul class="menu">
                            <li><a href="javascript:void(0)">Home</a></li>
                            <li><a href="javascript:void(0)">About Us</a></li>
                            <li><a href="javascript:void(0)">Our Team</a></li>
                            <li><a href="javascript:void(0)">Portfolio</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <ul class="menu">
                            <li><a href="javascript:void(0)">Faqs</a></li>
                            <li><a href="javascript:void(0)">Blog</a></li>
                            <li><a href="javascript:void(0)">Our Services</a></li>
                            <li><a href="javascript:void(0)">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-12 iq-mtb-10 iq-newsletter r9-mt-40">
            <h5 class="small-title iq-tw-6 iq-font-white iq-mb-30">Submit your query </h5>
                {/* <h5 class="small-title iq-tw-6 iq-font-white iq-mb-30">Newsletter</h5>
                <p class="iq-font-white">Subscribe to receive updates and industry information.</p> */}
                <form class="newsletter-form">
                    <div class="input-group">
                        <input type="text" class="form-control " 
                        placeholder="Enter your Name" 
                        value={this.state.name}
                        onChange={(e)=>{this.setState({name: e.target.value})} }
                        style={{marginLeft: '-2px' }}
                         />
                        <input type="email" class="form-control " 
                        placeholder="Enter your Email"
                        value={this.state.email}
                        onChange={(e)=>{this.setState({email: e.target.value})}}
                         />
                        <input type="text" class="form-control " 
                        placeholder="Enter your Phone"
                        value={this.state.phone}
                        onChange={(e)=>{this.setState({phone: e.target.value})}}
                         />
                        <input type="text" class="form-control " 
                        placeholder="Enter your Query"
                        value={this.state.query}
                        onChange={(e)=>{this.setState({query: e.target.value})}}
                         />

                        <a class="button" role="button" onClick={this.subMitQuery}>Submit</a>
                    </div>
                    <p className='successMalilMessage'>Your query is successfully send!!</p>
                </form>
            </div>
        </div>
        <hr class="r9-mt-40" />
    </div>
    <div class="container-fluid iq-pall">
        <div class="footer-bottom iq-ptb-20 dark-bg">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <div class="iq-copyright iq-font-white">© Copyright 2022 Creare Coin Developed by <a href="#" target="_blank">CreareCoin</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
      </>
    );
  }
}

export default Home;