import React from "react";
import { render } from "react-dom";

const styles = {
  fontFamily: "sans-serif",
  textAlign: "left"
};

class Test extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      totalPer:0

    };
  }

  handleChange = idx => e => {
    //console.log(this.props)
    var totalCoin = Number(this.props.totalCoin);
      var val = e.target.value;
    var v = Number(val);

      let rows = this.state.rows;
      if(e.target.name=='perc'){
        //console.log(v);
        rows[idx].perc = val;
        rows[idx].coin = (totalCoin*v)/100
      }
      if(e.target.name=='days'){
        rows[idx].days = val;
      }
      
    this.setState({rows: rows});
    this.props.parentCallback(this.state.rows);
  };
  handleAddRow = () => {
      //console.log(this.state);
      this.setState((prevState, props) => {
        var obj = {perc:'', days: ''};
        return { rows: [...prevState.rows, obj] };
    });
  };

  handleRemoveRow = () => {
    this.setState((prevState, props) => {
      return { rows: prevState.rows.slice(1) };
    });
  };

  componentDidMount(){
    //console.log('props')
    //console.log(this.props)
  }
  componentDidUpdate() {
    
    if(this.props.editData){
      if(this.props.editData.length>0 ){
        
    var editData = this.props.editData;
    //this.state.rows = this.props.editData;
    setTimeout(() => {
      if(this.props.totalCoin !=''){
        this.setState({rows: editData});
      }
     // 
      //console.log(this.state)
    }, 200);
        
      }
    }
    
    
  }

  render() {
    console.log(this.state);
    console.log(this.props)
    return (
      <div style={styles}>
        <table className="table table-bordered table-hover"
                id="tab_logic">
            <thead>
                  <tr>
                    <th className="text-center">Percentage</th>
                    <th className="text-center">Coin</th>
                    <th className="text-center">Holding period (days)</th>
                    <th>Action</th>
                  </tr>
                </thead>
          <tbody>
            {this.state.rows.map((row, idx)=> (
              <tr key={idx}>
                <td><input type='text' className="form-control" name="perc"
                  value={this.state.rows[idx].perc}
                  onChange={this.handleChange(idx)}
                 /> </td>
                 <td><input type='text' className="form-control" name="coin"
                  value={this.state.rows[idx].coin}
                  onChange={this.handleChange(idx)}
                 /> </td>
                <td><input type='date' className="form-control" name="days"
                value={this.state.rows[idx].days}
                onChange={this.handleChange(idx)}
                 /> </td>
                <td>
                    {Boolean(this.state.rows.length) && (
                    <button
                        className="btn btn-outline-danger btn-sm"
                        onClick={this.handleRemoveRow}
                    > <i class="material-icons">delete</i>
                    </button>
              )}</td>
              </tr>
            ))}
            <tr>
              <td colSpan={4}>
              <button onClick={this.handleAddRow} className="btn btn-primary">
                Add Row
              </button>
              
              </td>
              
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default Test;