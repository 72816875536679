// Userlist
import React, { Component } from 'react';
import Button from "react-bootstrap/Button";

import DataTable from 'react-data-table-component';
import FilterResults from 'react-filter-search';

// https://themesbrand.com/dason/layouts/default/tables-basic.html

class Userlist extends Component{
    constructor(props){
        super(props)
        this.state = {
            coins: [],
            userName: '',
            userId: 0,
            userType:0,
            user:'',
            searchId:'',
            searchName:'',
        };
        this.logOut = this.logOut.bind(this);
        this.seachById = this.seachById.bind(this);
        this.seachByName = this.seachByName.bind(this);
        this.searchByEmail = this.searchByEmail.bind(this);
        this.searchByAge = this.searchByAge.bind(this);
        this.searchByRole = this.searchByRole.bind(this);
        this.searchByPhone = this.searchByPhone.bind(this);
        this.searchByGender = this.searchByGender.bind(this);
    }

    seachById(se){
        this.setState({searchId: se})
        console.log(se)
    }
    
    seachByName(se){

        this.setState({searchName: se})
        let value = se;
        if (value !='') {
            var coins = this.state.coins.filter((coin)=>{
                return coin.fname.toLowerCase().search(value) != -1;
            });
            this.setState({coins: coins});
        }else{
            this.getAllCoins();
        }
       
    }
    searchByEmail(se){
        let value = se.target.value;
        if (value !='') {
        var coins = this.state.coins.filter((coin)=>{
                return coin.user_name.toLowerCase().search(value) != -1;
            });
            this.setState({coins: coins});
        }else{
            this.getAllCoins();
        }
    }
    searchByAge(se){
        let value = se.target.value;
        if (value !='') {
       var coins = this.state.coins.filter((coin)=>{
            return coin.age.toLowerCase().search(value) != -1;
        });
        this.setState({coins: coins});
        }else{
            this.getAllCoins();
        }
    }
    searchByRole(se){
        let value = se.target.value;
        if (value !='') {
       var coins = this.state.coins.filter((coin)=>{
            return coin.role.toLowerCase().search(value) != -1;
        });
        this.setState({coins: coins});
        }else{
            this.getAllCoins();
        }
    }
    searchByPhone(se){
        let value = se.target.value;
        if (value !='') {
       var coins = this.state.coins.filter((coin)=>{
            return coin.pnone.toLowerCase().search(value) != -1;
        });
        this.setState({coins: coins});
        }else{
            this.getAllCoins();
        }
    }
    searchByGender(se){
        let value = se.target.value;
        if (value !='') {
       var coins = this.state.coins.filter((coin)=>{
            return coin.gender.toLowerCase().search(value) != -1;
        });
        this.setState({coins: coins});
        }else{
            this.getAllCoins();
        }
    }
    
    getAllCoins(){
        setTimeout(() => {
            fetch(`
    https://crearecoin.com/api/coin/get_all_users.php`)
            .then(response => response.json())
            .then(data => {
                if(!data.error){
                    this.setState({coins: data.result});
                }
                //
                console.log(this.state.coins);
            });
        }, 200);
    }
    componentDidMount() {
        console.log('hit ')
        let user =JSON.parse(localStorage.getItem('user'));
        if(!user || user=== null){
            window.location.replace('./');
        }
        this.getAllCoins();
        if(user){
            this.setState({userName: user.name});
            this.setState({userId: user.id});
            this.setState({userType: user.user_type});
            this.setState({user: user});
        }
        
    }
    logOut(){
        console.log('logout')
        localStorage.removeItem("user");
        this.props.history.push({ 
            pathname: '/login'
        });
    }
    handleEdit(e){
        console.log(this.props)
        //this.props.history.push("adduser");
        this.props.history.push({ 
            pathname: '/adduser',
            state: e
        });
    }
    handleDelete(e){
        fetch(`
    https://crearecoin.com/api/coin/sign-up.php?delete=`+e.id)
            .then(response => response.json())
            .then(data => {
                this.getAllCoins();
            });
    }

    render(){
        return(
           <>
           <header class="menu-sticky">
                <div class="topnav" id="myTopnav">
                
                <a href="./"><img src='images/Creare-Coin_logo.png' /></a>
                
                { this.state.userId != -1 && 
                <a class="log" onClick={this.logOut} style={{float:'right' } }>
                        Logout
                </a> }
               
                </div>
            </header>
            <div class="main-contain">
                <section class="iq-tranding-Platform trending-2">
                    <div class="container-floud">
                    <div class="row black-background">
                            <div class="col-sm-6">
                                <div class="heading-title">
                                    
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="heading-title header-test">
                                    <p>{this.state.user.address } &nbsp;&nbsp;
                                    || &nbsp;&nbsp;
                                    {this.state.user.balance } {this.state.user.symbol }
                                    </p>
                                </div>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div className="col-sm-2 overview-block-pb">
                                <ul className="menu">
                                    <li><a href="/dashboard">DASHBOARD</a> </li>
                                    <li><a href="/rollmaster">User role master</a> </li>
                                    <li><a href="/adduser">Subscription Master</a> </li>
                                    <li><a href="/invite">Invite</a> </li>

                                    
                                    { this.state.userType =='1' &&  
                                    <li><a href="/report">Report</a> </li>}
                                </ul>
                            </div>
                            <div className="col-sm-10 white-background">
                            
                            <div class="table-responsive">
                     <table class="table table-bordered">
                        <thead>
                            <tr>
                                <td>
                                    
                                </td>
                                <td>
                                    <input type='text' value={this.state.searchName} onChange={(e)=>this.seachByName(e.target.value)}/>
                                </td>
                                <td>
                                    <input type='text' onChange={(e)=>this.searchByEmail(e)}/>
                                </td>
                                <td>
                                    <input type='text' onChange={(e)=>this.searchByAge(e)}/>
                                </td>
                                <td>
                                    <input type='text' />
                                </td>
                                <td>
                                    <input type='text' onChange={(e)=>this.searchByRole(e)}/>
                                </td>
                                <td>
                                    <input type='text' onChange={(e)=>this.searchByPhone(e)}/>
                                </td>
                                <td>
                                    <input type='text' onChange={(e)=>this.searchByGender(e)}/>
                                </td>
                                <td>
                                    
                                </td>
                            </tr>
                           <tr>
                              <th scope="col">#</th>
                              <th scope="col">Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Age</th>
                              <th scope="col">Date</th>
                              <th scope="col">Role</th>
                              <th scope="col">Phone</th>
                              <th scope="col">Gender</th>
                              <th scope="col">Action</th>
                           </tr>
                        </thead>
                        <tbody>
                            { this.state.coins &&
                        this.state.coins.map((coin, index) => (
                            <tr key={index}>
                              <th scope="row">{index+1} </th>
                              <td>{coin.name?coin.name:(coin.fname+' '+coin.lname)} </td>
                              <td>{coin.user_name} </td>
                              <td>{coin.age}</td>
                              <td>{coin.create_date}</td>
                              <td>{coin.role}</td>
                              <td>{coin.pnone}</td>
                              <td>{coin.gender}</td>
                              <td>
                              
                              <button type="button" 
                                class="btn btn-info btn-rounded waves-effect waves-light"
                                onClick={() => this.handleEdit(coin)}
                                >View & Edit</button>
                                <button type="button" 
                                class="btn btn-danger btn-rounded waves-effect waves-light"
                                onClick={() => this.handleDelete(coin)}
                                >Delete</button>
                              </td>
                           </tr>
                        ))
                        }
                           
                        </tbody>
                     </table>
                  </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
           </>
        )
    }
}
export default Userlist;